import './src/styles/global.css';

const React = require('react')
const Bugsnag = require('@bugsnag/js').default
const BugsnagPluginReact = require('@bugsnag/plugin-react').default

Bugsnag.start({
  apiKey: "7a736b17ae876005c68f0e59935e36ca",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production']
})

// Wrap entire app tree in the ErrorBoundary provided
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const wrapRootElement = ({ element }) => (
  <ErrorBoundary>{element}</ErrorBoundary>
)

export default wrapRootElement

export const onClientEntry = () => {
	const script = document.createElement('script');
	script.async = true;
	script.innerHTML = `
	 (function (w, d, s, l, i) {
	      w[l] = w[l] || []
	      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
	      var f = d.getElementsByTagName(s)[0],
	        j = d.createElement(s),
	        dl = l != 'dataLayer' ? '&l=' + l : ''
	      j.async = true
	      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
	      f.parentNode.insertBefore(j, f)
	    })(window, document, 'script', 'dataLayer', 'GTM-TLQZ2PD');
	  `;
	document.head.appendChild(script);

	const gtmTwo = document.createElement('script');
	gtmTwo.async = true;
	gtmTwo.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NGD6VJXD'); 
  `;
	document.head.appendChild(gtmTwo);
};